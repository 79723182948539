import * as R from 'ramda'
import {graphql} from 'gatsby'
import {shape} from 'prop-types'
import loadable from '@loadable/component'
import React from 'react'

import {brandsPropTypes} from 'helpers/propTypes'
import BrandsList from 'components/AboutUs/Brands'
import Hero from 'components/UI/Hero'
import Section from 'components/UI/Section'
import SEO from 'components/seo'

const Brands = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))

  const {
    callToActionBrand,
    imageOrVideo,
    metaTitle,
    metaDescription,
    pageTitle,
    shortDescription: {shortDescription},
    surtitle,
  } = R.pathOr(null, ['contentfulBrands'], data)
  const brandsByCountry = R.pathOr(
    null,
    ['allContentfulCountry', 'edges', 0, 'node', 'brands'],
    data,
  )

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <div className="decorationWrapper">
        <Decorations>
          <Decoration webSiteName="keyrus" color="blue" top={52} />
          <Decoration webSiteName="keyrus" color="orange" right={0} top={60} />
          <Decoration
            webSiteName="keyrus"
            color="red"
            right={0}
            bottom={-160}
          />
        </Decorations>
        <Section hasPaddingTop={false} hasPaddingBottom={false}>
          <Hero
            hat={surtitle}
            title={pageTitle}
            description={shortDescription}
            media={imageOrVideo}
            titleType="title"
          />
        </Section>
      </div>
      <Section
        hasGreyBackground
        hasSmallPadding
        hasPaddingTop={false}
        hasPaddingBottom={false}
        hasBorderBottom
      >
        {brandsByCountry && (
          <BrandsList
            cards={brandsByCountry}
            callToAction={callToActionBrand}
          />
        )}
      </Section>
    </>
  )
}

Brands.propTypes = brandsPropTypes

Brands.defaultProps = {
  ctaLink: '',
  ctaTitle: '',
  description: shape({description: ''}),
  media: null,
  surtitle: '',
  title: '',
}

export default Brands

export const pageQuery = graphql`
  query templateBrands(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulBrands(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      callToActionBrand
      imageOrVideo {
        file {
          url
          contentType
        }
        gatsbyImageData(placeholder: BLURRED)
        title
        description
      }
      surtitle
      metaTitle
      metaDescription {
        metaDescription
      }
      pageTitle
      shortDescription {
        shortDescription
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    allContentfulCountry(
      filter: {
        technicalName: {eq: $technicalName}
        node_locale: {eq: $nodeLocale}
      }
    ) {
      edges {
        node {
          id
          technicalName
          brands {
            id
            website
            name
            logo {
              file {
                url
              }
            }
            description {
              description
            }
          }
        }
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`
