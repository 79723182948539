import * as R from 'ramda'
import React from 'react'

import {brandsListPropTypes} from 'helpers/propTypes'
import Description from 'components/UI/Description'
import RoundButton from 'components/UI/RoundButton'
import Title from 'components/UI/Title'

import useStyles from './styles'

const BrandsList = ({callToAction, cards}) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {R.map(card => {
        const {id, logo, name, website} = card

        const description = R.pathOr('', ['description', 'description'], card)

        return (
          <article className={classes.card} key={id}>
            <div className={classes.image}>
              <img
                src={R.pathOr(null, ['file', 'url'], logo)}
                alt={R.pathOr(null, ['description'], logo)}
                loading="lazy"
              />
            </div>

            <div className={classes.infos}>
              <div className={classes.text}>
                <Title variant="h2" type="subTitle">
                  {name}
                </Title>
                <Description>{description}</Description>
              </div>
              <RoundButton arrow href={website} externalLink>
                {callToAction}
              </RoundButton>
            </div>
          </article>
        )
      }, cards)}
    </div>
  )
}

BrandsList.propTypes = brandsListPropTypes
BrandsList.defaultProps = {
  callToAction: '',
  cards: null,
}

export default BrandsList
