import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(
  ({palette, spacing, shadows, layout, breakpoints}) => ({
    wrapper: {
      color: 'black',
      margin: spacing(9, 0),
    },
    card: {
      maxWidth: layout.aboutUs.wideCard,
      position: 'relative',
      padding: spacing(5),
      display: 'flex',
      background: palette.background.default,
      boxShadow: shadows.medium,
      borderRadius: spacing(1, 5),
      marginBottom: spacing(5),
      marginRight: 'auto',
      marginLeft: 'auto',
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
        textAlign: 'center',
        '& a': {
          marginTop: spacing(3),
        },
      },
    },
    image: {
      height: layout.aboutUs.brandImgSize,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [breakpoints.down('sm')]: {
        alignItems: 'center',
      },
      '& img': {
        width: layout.aboutUs.brandImgSize,
        [breakpoints.down('sm')]: {
          marginBottom: spacing(4),
        },
      },
    },
    infos: {
      minHeight: layout.aboutUs.infosminHeight,
      [breakpoints.up('md')]: {
        marginLeft: spacing(5),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    },
    text: {
      paddingBottom: spacing(2),
      '& > p': {
        marginTop: spacing(1.25),
      },
    },
  }),
)

export default useStyles
